export default [
  {
    header: 'Quản lý',
  },
  {
    title: 'Booking',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Danh sách',
        route: 'manage-product-list',
      }
    ],
  }
]
